import React, { useState, useReducer } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import axios from 'axios';
import showdown from "showdown"
import IconSelect from '../icons/IconSelect';
import ModalResponse from '../ModalResponse';
import ReactMarkdown from 'react-markdown';

export default function StandingRegistrationForm() {
  const [postingData, setPostingData] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    standing: 'no-preference',
    consent1: 'no',
    consent2: 'no'
  };
  
  const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_FORM_INPUT':
            return {
                ...state,
                ...state.formInput,
                [action.payload.name]: action.payload.value,
            };
        case 'TOGGLE_CONSENT1':
            return { ...state, consent1: action.payload === true ? 'yes' : 'no' };
        case 'TOGGLE_CONSENT2':
            return { ...state, consent2: action.payload === true ? 'yes' : 'no' };
        default:
            return state;
    }
  };

  const [formData, setFormData] = useReducer(reducer, initialState);

  // = = = = = = = = 
  // Forestry Query
  const data = useStaticQuery(graphql`
    query StandingForm {
      unlistedForm: allMarkdownRemark(filter: {frontmatter: {form_title: {eq: "Standing registration"}}}) {
        nodes {
          frontmatter {
            form_consent
            thank_you_url
          }
        }
      }
    }
  `)

  const notificationSubject = "Speak for the City: Notification - New Standing for Submition";
  const notificationName = "standing.notification";
  const notificationDescription = "Template for Notification of the Standing For Form";
  const notificationTemplate = `
    <html>
      <body>
        <h1>Here is the submited information</h1>
        <h2>A message from</h2>
        <p>Name: {{userName}}</p>
        <p>Email: {{userEmail}}</p>
        <p>Phone: {{userPhone}}</p>
        <p>Message: {{userStandingFor}}</p>
        <p>Consent: {{userConsent}}</p>
      </body>
    </html>
  `;
  const confirmationSubject = "Speak for the City: Confirmation - New Standing For Submition";
  const confirmationName = "standing.confirmation";
  const confirmationDescription = "Template for Confirmation of the Standing For Form";
  const confirmationTemplate = `
    <html>
      <body>
        <p>Dear {{userName}}</p>
        <p>Thank you for contact us. We will get in thouch with you soon</p>
        <p>You are standing for: {{userStandingFor}}</p>
        <p>Kind regards,</p>
        <p>Speak for the City</p>
      </body>
    </html>
  `;


  const templateVariables = {
    userName: `${formData.firstName} ${formData.lastName}`,
    userEmail: formData.email,
    userPhone: formData.phone,
    userConsent: formData.consent,
    userStandingFor: formData.standing
  };

  const notificationMailgun = {notificationSubject, notificationName, notificationDescription, notificationTemplate, templateVariables};
  const confirmationMailgun = {confirmationSubject, confirmationName, confirmationDescription, confirmationTemplate, templateVariables};


  // = = = = = = = = 
  // Utils Functions

  // Convert Markdown to HTML
  const converter = new showdown.Converter();
  const htmlFormConsent = data.unlistedForm.nodes[0].frontmatter.form_consent;
  const htmlFormThankYouUrl = data.unlistedForm.nodes[0].frontmatter.thank_you_url;

  // = = = = = = = = 
  // Events Functions


  const displayResponseModal = () => {
    setShowResponse(true);
    setTimeout( () => setShowResponse(false), 3500)
  }

  // Add data to States
  // const handleChange = e => {
  //   // Set Form Data
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
    
  //   // Add consent if user didn't interact with it's radio buttons
  //   if(formData.consent === undefined) {
  //     setFormData({
  //       ...formData,
  //       consent: 'yes'
  //     });
  //   }
  //   // Add default standing value
  //   if(formData.standing === undefined) {
  //     setFormData({
  //       ...formData,
  //       standing: 'councillor'
  //     });
  //   }
  // }

  const handleChange = ( event ) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({ type: 'UPDATE_FORM_INPUT', payload: { name, value } });
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ type: `TOGGLE_${name.toUpperCase()}`, payload: checked });
  };

  // Handles Form Submition
  const handleSubmit = e => {
    e.preventDefault();
    let myForm = e.target;

    // Show spinner
    setPostingData(true);

    console.log('Form Data', formData);
    
    // Send to Netlify Forms
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": myForm.getAttribute('name'),
        ...formData
      }).toString()
    })
    .then(async (response) => {
      if (!response.ok) throw Error(response.statusText);
      // Send data to Serverless Function
      axios.post('/.netlify/functions/mailGunFinal', {
        notificationMailgun: 'standing.notification',
        notificationSubject: 'Notification of Standing as a Candidate Form Submition',
        confirmationMailgun: 'standing.confirmation',
        confirmationSubject: 'Thank you for registering your interest in standing',
        userName: `${formData.firstName} ${formData.lastName}`,
        userEmail: formData.email,
        userPhone: formData.phone,
        standingFor: formData.standing,
      })
    })
    .then(response => {
      // Hide spinner
      setPostingData(false);
      // Empty Form to clean up Form Fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        standing: 'no-preference',
        consent1: 'no',
        consent2: 'no'
      });
      // Show Modal Response
      //  displayResponseModal();
      htmlFormThankYouUrl !== undefined ? window.location.href = htmlFormThankYouUrl : displayResponseModal();
    })
    .catch((error) => {
      setPostingData(false);
      alert(error);
    })
  }

  return (
    <>
      <form 
        onSubmit={ (e) => handleSubmit(e) } 
        className="form form--page"
        name="Standing Registration Form"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        id="standing-registration-form"
      >
        <input type="hidden" name="form-name" value="Standing Registration Form" />

        <div className="form__wrapper">
          <div className="field-group">
            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="text" 
                value={formData.firstName} 
                name="firstName" 
                id="first-name" 
                placeholder="Your first Name*"
                required
              />
              <span className={`input__custom-label ${ formData.firstName ? 'show' : '' }`}>First Name</span>
            </div>

            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="text" 
                value={formData.lastName} 
                name="lastName" 
                id="last-name" 
                placeholder="Your last Name*"
                required
              />
              <span className={`input__custom-label ${ formData.lastName ? 'show' : '' }`}>Last Name</span>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <input 
              onChange={(e) => handleChange(e)} 
              type="email" 
              value={formData.email} 
              name="email" 
              className="required email" 
              id="email" 
              placeholder="Your Email Address*"
              required
              />
              <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Email</span>
            </div>

            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="tel" 
                value={formData.phone} 
                name="phone" 
                className="required phone" 
                id="phone" 
                placeholder="Your Mobile Phone"
              />
              <span className={`input__custom-label ${ formData.phone ? 'show' : '' }`}>Phone</span>
            </div>
          </div>

          <div className="input-wrapper">
            <label htmlFor="select-standing" className="select__label">Select from the list below</label>
            <div className="select__wrapper">
              
              <select  onChange={ (e) => handleChange(e) } className="select__input" name="standing" id="select-standing">
                <option className="select__option" value="">Select</option>
                <option className="select__option" value="councillor">Common Councillor</option>
                <option className="select__option" value="alderman">Alderman</option>
                <option className="select__option" value="no-preference">No preference at this time</option>
              </select>
              
              <div className="select__icon">
                <IconSelect />
              </div>

            </div>
          </div>

        </div>

        <div className="form__consent">

          <ReactMarkdown className="consent__text">{htmlFormConsent}</ReactMarkdown>

          <div className="consent__inputs mb-2">

            <div className="checkbox-wrapper">
              <input className='checkmark' onChange={handleCheckboxChange} id="consent1" name="consent1" type="checkbox" checked={formData.consent1 === 'yes' ? true : false} />
              <label htmlFor="consent1">Communications related to the Speak for the City election engagement campaign, including information on my registration status</label>
            </div>
            <div className="checkbox-wrapper">
              <input className="checkmark" onChange={handleCheckboxChange} id="consent2" name="consent2" type="checkbox" checked={formData.consent2 === 'yes' ? true : false} />
              <label htmlFor="consent2">Community engagement updates, such as information about upcoming events and consultations</label>
            </div>

            </div>  

            <div className="consent__text">
              <p>For full details on how the City of London Corporation uses your information, <a href="/privacy-policy">please see our privacy notice here</a></p>
              <p><small>Please note that you can withdraw your consent at any time by contacting vote@cityoflondon.gov.uk</small></p>
            </div>

        </div>

        <div className="button-group">
          <div className="button button--submit">
            {
              postingData !== true
                ? <input readOnly type="submit" value="Submit" name="submit" id="company-submit" />
                
                : <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
            }
          </div>
        </div>          
      </form>
      {
        showResponse ? <ModalResponse /> : ''
      }
    </>
  )
}