import React from 'react';

export default function ModalResponse () {
  
return (
    <div id="modal-response" className="modal-response">
      <div className="content-wrapper response">
        <h3 className="response__headline">Thank you for contacting us.</h3>
        <p>We will be in contact shortly</p>
      </div>
    </div>
  )
}